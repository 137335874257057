// @import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@100;200;300;400;500;600;700;800&display=swap');

.text--grey{
    color: #797979!important;
}
.text--black1{
    color: #505458;
}
.text--primary{
    color: #30649B!important;
}
.bg--grey{
    background-color: #D9D9D9!important;
}
.bg--gray {
    background-color: #F5F6FA!important;
}
.mt10 {
    margin-top: 10px;
}

.mt30 {
    margin-top: 30px;
}

mt20 {
    margin-top: 20px;
}
.ml-2, .mx-2 {
    margin-left: 0.5rem!important;
}
.pl-4, .px-4 {
    padding-left: 1.5rem!important;
}
body{
    font-family: "Sarabun", sans-serif!important;
}
// .ant-layout,.adminContentSection{
//     background: #F8F8F8!important;
// }

.navbar {
    padding: inherit;
}
.container-fluid {
    display: block!important;
}
.searchOpacity{
    opacity : 0.5;
}
h5 {
    font-size: inherit;
}
.displayFlex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.alignCenter {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.spaceBetween {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.flexdirectioncolumn {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.flexStart {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.justifyflexend {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.justify-content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flexWrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.custom--checkbox .ant-checkbox-wrapper{
    margin-right: 8px;
    margin-left: 0px!important;
    margin-bottom: 10px;
}

.supportSearchBox input::-webkit-input-placeholder {
    color: #333;
    font-size: 14px;
}

.supportSearchBox input::-moz-placeholder {
    color: #333;
    font-size: 14px;
}

.supportSearchBox input:-ms-input-placeholder {
    color: #333;
    font-size: 14px;
}

.supportSearchBox input:-moz-placeholder {
    color: #333;
    font-size: 14px;
}


select {
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    height: 38px !important;
    border-radius: 4px;
}

.selectPicker {
    height: 40px !important
}

.selectPicker .ant-select-selector {
    height: 40px !important;
    line-height: 40px;
}

.selectPicker .ant-select-selection-item {
    height: 40px !important;
    line-height: 40px !important;
}

.search {
    position: relative;
    margin-right: 5px;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    height: 38px !important;
    border-radius: 4px;
    padding-left: 10px;
}

.search input {
    border: 0px !important;
    height: 36px !important;
    border-radius: 4px;
}

.search input:focus,
search input:focus-visible {
    box-shadow: none;
    border: 0px solid blue !important;
}

.search .fa-search {
    position: absolute;
    top: 10px;
    left: 16px;
}

.maintable {
    width: 100%;
    overflow: auto;
}

.maintable table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 5px;
}

.maintable table tr {
    background: #fff;
}

.maintable table tr:hover {
    // -ms-transform: scale(1.01);
    // -webkit-transform: scale(1.01);
    // transform: scale(1.01);
}

.maintable table tr th {
    background: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    text-align: left;
    padding: 10px 5px;
    white-space: nowrap;
}

.maintable table tr th:last-child,
.maintable table tr td:last-child {
    text-align: center;
}

.maintable table tr td {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #797979;
    padding: 10px 5px;
}

.maintable table tr td .iconBox {
    width: 50px;
    height: 50px;
    background-color: #F7D95F;
    padding: 2px;
}

.paginationContainer {
    background: #fff;
    padding: 10px 10px 8px 10px;
}

.pageNumbers {
    list-style: none;
    display: flex;
}

.pageNumbers li {
    padding: 5px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.pageNumbers li.active {
    background-color: #30649B;
    border-color: #30649B;
    color: white;
}

.pageNumbers li button {
    background-color: transparent;
    border: none;
    color: black;
    font-size: 1;
    cursor: pointer;
}

.pageNumbers li button:hover {
    background-color: #e9ecef;
    border-color: #dee2e6;
    color: #57677c;
}

.pageNumbers li button:focus {
    outline: none;
}

.btn--small,
.btn--small:hover {
    background-color: #F7D95F;
    border-color: #F7D95F;
    padding: 1px 5px !important;
    font-size: 12px;
    border-radius: 2px;
    color: #fff;
}

.btn--view .anticon-eye,.btn--view .anticon-edit{
    position: relative;
    top: -4px;
    left: 0px;
}

.btn-secondary{
    background-color: #F7D95F!important;
    border-color: #F7D95F!important;
    border-radius: 2px!important;
    font-size: 14px!important
}

.btn--cancel{
    background-color:#797979!important;
    color: #fff!important;
    border-color: #797979!important;
}

.profileDetailsLeft {
    padding: 20px;
    height: 100%;
}

.profileDetailsLeft .shortname {
    font-size: 4rem;
    background: #27271B;
    color: #fff;
    text-align: center;
    padding-top: 26px;
    padding-bottom: 16px;
}

.profileDetailsLeft .fullname {
    font-size: 1rem;
    background: #30649B;
    color: #fff;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
}
.profileDetailsLeft .fullname p{
    margin-bottom: 0px;
}
.profileDetailsRight {
    padding: 20px;
    height: 100%;
}

.ogShortName {
    background: #27271B;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}

.supportTabs .ant-tabs-nav {
    background-color: #fff;
}

.supportTabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab-btn {
    text-transform: uppercase;
    font-weight: 500;
}

.divoverlay {
    background-color: rgba(0, 0, 0, 0.8);
    left: 0px;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1000;
}

.divoverlayBlock {
    width: 800px;
    background-color: #fff;
}
.divoverlaySmallBlock {
    width: 500px!important;
    background-color: #fff;
    padding: 25px;
}

.divoverlayBlock h5 {
    font-size: 16px;
}

.divoverlayBlock .countryIcon {
    height: 40px !important;
}

.divoverlayBlock .countryIcon img {
    width: 25px;
}

.divoverlayBlock .popupheaderblock {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #CCCCCC;
    padding: 10px;
    margin-bottom: 15px;
}

.divoverlayBlock .popupheaderTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #0C0A0B;
}

.divoverlayBlock label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #000000;
    padding-bottom: 10px;
}

.divoverlayBlock .form-control {
    height: 40px !important;
    font-size: 14px;
}

.divoverlayBlock .form-select {
    background-color: #F5F5F5;
    font-size: 14px;
    height: 40px !important;
}

.divoverlayBlock .overdivScroll {
    min-height: 400px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}
.divoverlayBlock .overdivScroll::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.divoverlayBlock .overdivScroll::-webkit-scrollbar-track {
    background: #ccc;
    border-radius: 20px;
}

/* Handle */
.divoverlayBlock .overdivScroll::-webkit-scrollbar-thumb {
    background: #30649B;
    border-radius: 20px;
}
.supportForm input{
    border-color: #D9D9D9;
    height: 45px;
}

.order-collapse{
    padding: 10px!important;
    background-color: #fff!important;
}
.order-collapse .ant-collapse-item{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    margin-bottom: 15px;
}
.order-collapse .ant-collapse-item .ant-collapse-header{
    font-weight: 600!important;
    text-transform: uppercase;
}
.order-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow{
    font-size: 16px!important;
    color:#30649B!important;
    top: 16px!important;
}

.order-collapse .ant-collapse-item .ant-collapse-content p{
    border-bottom: 1px solid #ddd
}
.order-collapse .ant-collapse-item .ant-collapse-content p:last-child{
    border-bottom: 0px solid #ddd!important
}
.order-collapse .ant-collapse-item .ant-collapse-content p span{
    padding-bottom: 5px;
    display: inline-block;
}
.text-grey {
    color: #666;
}
.border-bottom{
    border-bottom: 1px solid #ddd
}
.editProfileForm .ant-form-item-label label{
    font-size: 16px;
    color: #797979!important;
}
.editProfileForm .ant-input,.editProfileForm .ant-select,.editProfileForm .ant-select .ant-select-selector{
    height: 45px!important;
    background-color: #EAECEB!important;
    border-radius: 0px!important;
}

.editProfileForm .ant-checkbox+span {
   color: #797979!important;
}

.terminalIdText{
    display: flex;
    width: 100%;
    word-break: break-all;
}

/*Receipt*/
.align-top{
    vertical-align: top!important;
}
.bg-gray {
    background-color: #F5F6FA;
}
.border-l-dashed {
    border-left: 1px dashed #ddd;
}
.border-t-dashed{
    border-top: 1px dashed #ddd;
}
.receipt-sec .header-sec {
    background: #fff;
    padding: 6px 12px;
}

.receipt-sec .header-sec .btn-add {
    color: #30649B;
    padding: 6px 20px!important;
}

.receipt-sec .body-sec {
    background-color: #fff;
}

.receipt-sec .body-sec .address-sec .address-sec-left {
    padding: 0rem 1rem;
    border-radius: 5px;
    height: 160px;
    width: 135px;
}

.receipt-sec .body-sec .address-sec .address-sec-left .icon-Group-4249:before {
    color: #BEBEBE!important;
}

.receipt-sec .body-sec .address-sec .address-sec-left img {
    height: 100%;
    object-fit: contain;
}

.receipt-sec .body-sec .address-sec .address-sec-right {
    border-radius: 5px;
}

.receipt-item-table thead {
    background: #F5F6FA;
}

.receipt-item-table thead th {
    border-bottom: 0px!important;
    text-transform: uppercase;
    background: #F5F6FA!important;
}

.receipt-item-table tbody tr td {
    vertical-align: middle;
    color: #797979;
    border-bottom: 0px!important;
}

.receipt-item-table tbody:first-child tr td {
    border-top: 0px!important
}

.receipt-item-table tbody tr:first-child td {
    border-top: 0px!important
}

.receipt-item-table tbody tr td:first-child {
    padding-left: 0px!important;
}

.receipt-item-table tbody tr td .product-name {
    border-radius: 50%;
    color: #30649B;
    background: #F5F6FA;
    margin-right: 10px;
    font-weight: 600;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: block;
    text-align: center;
    padding: 0px 14px;
}

.receipt-item-table tbody tr td h3 {
    font-size: 0.9rem;
    padding: 0px 0!important;
}

.receipt-sec .body-sec .payment-details-sec {
    border-top: 8px solid #f8f8f8
}

.receipt-sec .body-sec .payment-details-sec .heading li {
    list-style-type: none;
    color: #30649B;
    font-size: 1.1rem;
    font-weight: 600;
    float: left;
    padding: 6px 26px;
    border-bottom: 2px solid #30649B;
}

.receipt-sec .body-sec .payment-details-sec table tr td {
    border-top: 1px dashed #dee2e6;
}

.receipt-sec .body-sec .payment-details-sec table tr:first-child td {
    border-top: 0px!important
}

.receipt-sec .body-sec .payment-details-sec table tr td,
.receipt-sec .body-sec .payment-details-sec table tr th {
    padding: .4rem .75rem!important;
}

.receipt-sec .body-sec .payment-details-sec table tr th {
    border: 0px!important
}

.receipt-sec .body-sec .payment-details-sec .payment-details-inner {
    margin-top: -6px;
}

.receipt-sec .body-sec .payment-details-sec .payment-details-amount-sec h5 {
    color: #999;
    font-size: 1.1rem;
    font-weight: normal;
    margin: 10px 0;
}

.receipt-sec .body-sec .payment-details-sec .payment-details-amount-sec h4 {
    font-size: 1.3rem!important;
    font-weight: normal;
    margin: 10px 0;
}

.receipt-sec .body-sec .order-list-sec td:first-child {
    padding-left: 3.8rem!important;
}

.receipt-sec .body-sec .order-list-sec td p {
    line-height: 20px;
    font-size: 14px;
}

.receipt-sec .body-sec tr.order-list-sec td {
    border-top: 0px!important;
    border-bottom: 1px dashed #cfcfcf;
}

.receipt-sec .body-sec tr.order-list-sec:last-child td {
    border-bottom: 0px;
}

.receipt-sec .body-sec .receipt-item-table tbody+tbody {
    border-top: 1px solid #dee2e6!important;
}

.receipt-sec .body-sec .payment-details-sec .tax-details-sec table tr th, .receipt-sec .body-sec .payment-details-sec .tax-details-sec table tr td {
    padding: 0.4rem 0rem !important;
    border-bottom: 0px;
}

.btn-add,.btn-add:hover {
    background: #fff;
    text-transform: uppercase;
    font-size: .9rem;
    padding: 4px 20px;
    border-radius: 5px;
    color: #00355F;
    font-weight: 600;
}

.passwordIcon{
    position: absolute;
    right: 10px;
    top: 15px;
}

.selectDropdown .ant-select-single .ant-select-selector .ant-select-selection-item,
.selectDropdown .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 45px!important;
}
.selectDropdown .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 42px!important;
}

.staffAdminForm .ant-form-item-label>label.ant-form-item-required::before{
    right: -14px;
    top: 4px;
    position: absolute;
}

.prefixInput .ant-input-affix-wrapper{
    padding: 0px!important;
    border-radius: 0px;
}       

.prefixInput .ant-input-affix-wrapper .ant-input-prefix{
    height: 45px!important;
    background-color: #EAECEB !important;
    border-radius: 0px!important;
    margin: 0px;
    padding: 0px 10px!important 
}
.merchantParameterBox label {
    font-family: "Sarabun", sans-serif;
    color: #797979;
    font-size: 14px;
}
.merchantTextBox{
    width: 240px;
}
.merchantTextBox .form-control{
    height: 42px!important;
    border: 1px solid #ccc!important;
    border-radius: 5px;
    padding: 7px 15px !important;
    background: #EAECEB!important;
}

.ant-select-selector{
    display: flex;
    align-items: center;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    display: flex;
    align-items: center;
}
