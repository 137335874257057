/*Media Query*/
@media only screen and (max-width: 991px)  {
    .left-heading-right-button{
        display: block;
     }
    .filterBox{
        .ant-col{
            margin-bottom: 5px;
            margin-left: initial;
            display: block!important;
            width: 100%;
            &:nth-of-type(1){
                padding-left: 6px!important;
            }
        }
    }
    .content-body{
        .ant-row{
            .ant-col {
                .bg-white{
                    margin: 0px 14px 14px 14px!important;
                    padding: 14px!important;
                }
            }
        }
    } 
    .itemsummaryTable{
        .ant-table-content{
            overflow: auto hidden;
        }
    }
}
@media only screen and (max-width: 991px) and (orientation:landscape) {
    .left-heading-right-button{
        display: flex;
     }
    .filterBox{
        .ant-col{
            width: auto;
            margin-right: 5px!important;
            &:nth-of-type(1){
                padding-left: 0px!important;
            }
        }
    }
}
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait){
    .left-heading-right-button{
        display: flex;
     }
    .filterBox{
        .ant-col{
            width: auto;
            &:nth-of-type(1){
                padding-left: 0px!important;
            }
        }
    }
}
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape){
    .filterBox{
        .ant-col{
            width: auto;
            &:nth-of-type(1){
                padding-left: 6px!important;
            }
        }
    }
}