.productInventorySec{
  .ant-row{
    margin-left: 0px!important;
    margin-right: 0px!important;
  }
}
.timeClockManagementTable{
    @import '../../../../assets/css/common.less';
    .ant-table table{
      tr{        
        &:last-child td{
          .br-b-0()!important;
        }
        td{
            .orderLabel {
                font-weight: 500;
                color: #777!important;
            }
            .plus{
              color:green
            }
            .minus{
              color:red
            }
        }
      }
    }
  }

  .hiddenItem{
    margin-bottom: 0px!important;
    line-height: 0px!important;
    .ant-form-item-control-input{
      min-height: 0px!important;
    }
  }
