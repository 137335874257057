.qrCodeContainer {
    position: relative;

    .qrCode {
        opacity: 1;
        display: block;
        width: 100%;
        height: auto;
        transition: .5s ease;
        backface-visibility: hidden;
    }

    .qrCodeDownload {
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        .icon{
            width: 100px;
            height: 100px;
            line-height: 75px;
            border-radius: 50%;
            background-color: #30649B;
            font-size: 3rem;
            color: #fff;
        }
    }

    &:hover .qrCode {
        opacity: 0.3;
    }

    &:hover .qrCodeDownload {
        opacity: 1;
    }
}
