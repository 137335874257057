/*Common*/
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.bg-white {
  background: #fff;
}
.bg-primary {
  background-color: #30649B !important;
}
.m-0 {
  margin: 0px;
}
.m-10 {
  margin: 10px!important;
}
.mLR10 {
  margin: 0px 10px;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mr-0 {
  margin-right: 0rem!important;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mr-5 {
  margin-right: 1.25rem;
}
.mb-0 {
  margin-bottom: 0px!important;
}
.mb-02 {
  margin-bottom: 0.2rem !important;
}
.mb-05 {
  margin-bottom: 0.5rem !important;
}
.mb-1 {
  margin-bottom: 1rem!important;
}
.m-tb1 {
  margin-top: 10px!important;
  margin-bottom: 10px!important;
}
.ml-05 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-18 {
  margin-left: 18px;
}
.ml-36 {
  margin-left: 36px;
}
.btn-mr8 {
  margin-right: 8px;
}
.p-0 {
  padding: 0px!important;
}
.pl-0 {
  padding-left: 0px!important;
}
.pr-0 {
  padding-right: 0px!important;
}
.pLR10 {
  padding: 0px 10px;
}
.p-x8-y16 {
  padding: 8px 16px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}
.fs-08 {
  font-size: 0.8rem;
}
.fs-09 {
  font-size: 0.9rem;
}
.fs--1 {
  font-size: 1rem;
}
.fs-15 {
  font-size: 1.5rem;
}
.fs-2 {
  font-size: 2rem;
}
.fs--5 {
  font-size: 5rem;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.br-b-0 {
  border-bottom: 0px;
}
.br-r-0 {
  border-right: 0px;
}
.cursor-pointer {
  cursor: pointer;
}
.btn--large {
  height: 38px!important;
  padding: 4px 35px!important;
}
/*Common*/
/*Heading - Left side title and right side button*/
.left-heading-right-button {
  display: flex;
  margin-bottom: 10px;
}
.left-heading-right-button h4 {
  flex-grow: 1;
}
.left-heading-right-button .space-ml-8 {
  margin-left: 8px;
}
/*Chart*/
.tooltipHeader {
  font-size: 0.9rem !important;
}
.salessummaryTable .ant-table table tr:last-child td {
  border-bottom: 0px;
}
.salessummaryTable .ant-table table tr td .ant-table-row-expand-icon {
  display: none;
}
.salessummaryTable .ant-table table tr[data-row-key="1"] td,
.salessummaryTable .ant-table table tr[data-row-key="grossSales"] td,
.salessummaryTable .ant-table table tr[data-row-key="netSales"] td,
.salessummaryTable .ant-table table tr[data-row-key="total"] td {
  font-weight: 700;
}
.salessummaryTable .ant-table table tr[data-row-key="tax"] td .ant-table-row-expand-icon {
  float: right;
  left: -85px;
  display: block;
  margin-top: 3.5px;
}
.paymentcollectedTable .ant-table table tr div {
  display: flex;
}
.paymentcollectedTable .ant-table table tr div .icon {
  padding: 6px;
  background-color: #30649B;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
}
.paymentcollectedTable .ant-table table tr div span {
  align-items: center;
  display: flex;
}
.paymentcollectedTable .ant-table table tr:last-child td {
  border-bottom: 0px !important;
}
.paymentcollectedTable .ant-table table tr [data-row-key="2"]:nth-of-type(1) td,
.paymentcollectedTable .ant-table table tr [data-row-key="2"]:nth-of-type(2) td,
.paymentcollectedTable .ant-table table tr [data-row-key="2"]:nth-of-type(3) td,
.paymentcollectedTable .ant-table table tr [data-row-key="2"]:nth-of-type(4) td {
  padding: 8px 16px !important;
}
.paymentcollectedTable .ant-table table tr[data-row-key="totalcollected"] td,
.paymentcollectedTable .ant-table table tr[data-row-key="nettotal"] td {
  font-weight: 700 !important;
}
