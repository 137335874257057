.sell-wrp {
  @import "../../../../assets/css/common.less";
  .btn-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      font-size: 12px;
    }
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
    border-bottom: none;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .ant-radio-inner::after {
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
  }
  .btn-yellow:hover {
    background-color: #f7d95f;
    border-color: #f7d95f;
  }
  .btn-yellow {
    background-color: #f7d95f;
    border-color: #f7d95f;

    span {
      color: #000;
      font-weight: 600;
    }
  }
  .error {
    font-size: 12px;
    color: red;
  }
  .sell-dtl-lft {
    padding: 15px;
    .cart-section {
      // height: 150px;
      // overflow-y: auto;
      padding-right: 20px;
      table {
        width: 100%;

        tr {
          border-bottom: 1px solid #ccc;
          td {
            padding: 3px 0;
            .quantity-input {
              padding: 0px 10px;
            }
            .add-to-cart-return {
              float: right;
              width: 113px;
              padding: 15px;
              border-radius: 0px;
            }
            .delete-btn {
              background: #fff;
              .anticon-delete {
                background-color: transparent;
                color: #e50000;
                font-weight: bold;
              }
            }
          }
          button {
            height: 20px;
            width: 20px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            cursor: pointer;
            border: unset;
            .qantity-btn {
              background-color: #ccc;
            }
          }
        }
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    table {
      tfoot tr {
        border-top: 1px solid #ccc;
      }
      td {
        padding: 3px 0;
      }
    }
  }
  .sell-dtl-right {
    padding: 15px;
    .cart-section {
      height: 320px;
      overflow-y: auto;
      padding-right: 20px;
      table {
        width: 100%;
        tr {
          border-bottom: 1px solid #ccc;
          td {
            padding: 10px 0;
            .quantity-input {
              padding: 0px 10px;
            }
            .delete-btn {
              background: #fff;
              .anticon-delete {
                background-color: transparent;
                color: #e50000;
                font-weight: bold;
              }
            }
          }
          button {
            height: 20px;
            width: 20px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            cursor: pointer;
            border: unset;
            .qantity-btn {
              background-color: #ccc;
            }
          }
        }
      }
    }
  }
}
.ant-input-number-handler-wrap {
  display: none;
}

