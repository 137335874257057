@primary-color: #30649B; // primary color for all components
@secondary-color: #F7D95F; // primary color for all components
@link-color: #1890ff; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
@text-color-light: #fff; // white state color
@text-color-dark: #000; // black state color


/*Common*/
.d-none{
  display: none;
}
.d-block{
  display: block;
}
.d-flex{
  display: flex;
}
.bg-white {
  background: #fff;
}
.bg-primary{
  background-color: @primary-color!important;
}
.m-0{
  margin: 0px;
}
.m-10{
  margin: 10px!important;
}
.mLR10{
  margin: 0px 10px;
}
.ml-auto{
  margin-left: auto;
}
.mr-auto{
  margin-right: auto;
}
.mr-0{
  margin-right: 0rem!important;
}
.mr-3{
  margin-right: 0.75rem;
}
.mr-1{
  margin-right: 0.25rem!important;
}
.mr-5{
  margin-right: 1.25rem;
}
.mb-0{
  margin-bottom: 0px!important;
}
.mb-02{
  margin-bottom: 0.2rem!important;
}
.mb-05{
  margin-bottom: 0.5rem!important;
}
.mb-1{
  margin-bottom: 1rem!important;
}
.m-tb1{
  margin-top:10px!important;
  margin-bottom: 10px!important;
}
.ml-05{
  margin-left: 5px;
}
.ml-10{
  margin-left: 10px;
}
.ml-18{
  margin-left: 18px;
}
.ml-36{
  margin-left: 36px;
}
.btn-mr8{
  margin-right: 8px;
}
.p-0{
  padding: 0px!important;
}
.pl-0{
  padding-left: 0px!important;
}
.pr-0{
  padding-right: 0px!important;
}
.pLR10{
  padding: 0px 10px;
}
.p-x8-y16{
  padding: 8px 16px;
}
.float-left{
  float: left
}
.float-right{
  float: right
}
.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}
.text-uppercase{
  text-transform: uppercase;
}
.fs-08{
  font-size: 0.8rem;
}
.fs-09{
  font-size: 0.9rem;
}
.fs--1{
  font-size: 1rem;
}
.fs-15{
  font-size: 1.5rem;
}
.fs-2{
  font-size: 2rem;
}
.fs--5{
  font-size: 5rem;
}
.fw-500{
  font-weight: 500
}
.fw-600{
  font-weight: 600
}
.fw-700{
  font-weight: 700
}
.br-b-0{
  border-bottom:0px
}
.br-r-0{
  border-right:0px
}
.cursor-pointer{
  cursor: pointer;
}
.btn--large{
  height: 38px!important;
  padding: 4px 35px!important;
}
/*Common*/

/*Heading - Left side title and right side button*/
.left-heading-right-button{
   display: flex;
   margin-bottom: 10px;
   h4{
     flex-grow: 1;
   }
   .space-ml-8{
     margin-left: 8px;
   }
}

/*Chart*/
.tooltipHeader{
  font-size:.9rem!important;
}

