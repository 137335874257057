.btn-disable{
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.ant-btn-dark {
    color: #fff!important;
    background-color: #343a40!important;
    border-color: #343a40!important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    &:hover,&:focus {
      color: #fff!important;
      background-color: rgba(52, 58, 64, 0.75)!important;
      border-color: rgba(52, 58, 64, 0.1)!important;
    }
    &:active,
    &.active {
      color: #fff!important;
      background-color: #1d2124!important;
      border-color: #1d2124!important;
    }
  } 
  .ant-btn-dark-disabled,
  .ant-btn-dark.disabled,
  .ant-btn-dark[disabled],
  .ant-btn-dark-disabled:hover,
  .ant-btn-dark.disabled:hover,
  .ant-btn-dark[disabled]:hover,
  .ant-btn-dark-disabled:focus,
  .ant-btn-dark.disabled:focus,
  .ant-btn-dark[disabled]:focus,
  .ant-btn-dark-disabled:active,
  .ant-btn-dark.disabled:active,
  .ant-btn-dark[disabled]:active,
  .ant-btn-dark-disabled.active,
  .ant-btn-dark.disabled.active,
  .ant-btn-dark[disabled].active {
     .btn-disable();
  }


  .ant-btn-background-ghost.ant-btn-dark {
    color: #343a40!important;
    background: transparent!important;
    border-color: #343a40!important;
    text-shadow: none;
    &:hover,&:focus {
      color: rgba(52, 58, 64, 0.75)!important;
      background: transparent!important;
      border-color: rgba(52, 58, 64, 0.75)!important;
    }
    &:active,
    &.active {
      color: #343a40!important;
      background: transparent!important;
      border-color: #1d2124!important;
    }
  }

 
  .ant-btn-lightdark {
    color: #fff!important;
    background-color: #6c757d!important;
    border-color: #6c757d!important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    &:hover,&:focus {
      color: #fff!important;
      background-color: rgba(108, 117, 125, 0.75)!important;
      border-color: rgba(108, 117, 125, 0.1)!important;
    }
    &:active,
    &.active {
      color: #fff!important;
      background-color: #545b62!important;
      border-color: #545b62!important;
    }
  }
  .ant-btn-lightdark-disabled,
  .ant-btn-lightdark.disabled,
  .ant-btn-lightdark[disabled],
  .ant-btn-lightdark-disabled:hover,
  .ant-btn-lightdark.disabled:hover,
  .ant-btn-lightdark[disabled]:hover,
  .ant-btn-lightdark-disabled:focus,
  .ant-btn-lightdark.disabled:focus,
  .ant-btn-lightdark[disabled]:focus,
  .ant-btn-lightdark-disabled:active,
  .ant-btn-lightdark.disabled:active,
  .ant-btn-lightdark[disabled]:active,
  .ant-btn-lightdark-disabled.active,
  .ant-btn-lightdark.disabled.active,
  .ant-btn-lightdark[disabled].active {
    .btn-disable();
  }

  .ant-btn-background-ghost.ant-btn-lightdark {
    color: #6c757d!important;
    background: transparent!important;
    border-color: #6c757d!important;
    text-shadow: none;
    &:hover,
    &:focus {
        color: rgba(108, 117, 125, 0.75)!important;
        background: transparent!important;
        border-color: rgba(108, 117, 125, 0.75)!important;
    }
    &:active,
    &.active {
      color: #6c757d!important;
      background: transparent!important;
      border-color: #545b62!important;
    }
  }
  

  .ant-btn-success {
    color: #fff!important;
    background-color: #28a745!important;
    border-color: #28a745!important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    &:hover,
    &:focus {
        color: #fff!important;
        background-color: rgba(40, 167, 69, 0.75)!important;
        border-color: rgba(40, 167, 69, 0.1)!important;
    }
    &:active,
    &.active {
        color: #fff!important;
        background-color: #1e7e34!important;
        border-color: #1e7e34!important;
    }
  }
  .ant-btn-success-disabled,
  .ant-btn-success.disabled,
  .ant-btn-success[disabled],
  .ant-btn-success-disabled:hover,
  .ant-btn-success.disabled:hover,
  .ant-btn-success[disabled]:hover,
  .ant-btn-success-disabled:focus,
  .ant-btn-success.disabled:focus,
  .ant-btn-success[disabled]:focus,
  .ant-btn-success-disabled:active,
  .ant-btn-success.disabled:active,
  .ant-btn-success[disabled]:active,
  .ant-btn-success-disabled.active,
  .ant-btn-success.disabled.active,
  .ant-btn-success[disabled].active {
    .btn-disable();
  }
  
  .ant-btn-background-ghost.ant-btn-success {
    color: #28a745!important;
    background: transparent!important;
    border-color: #28a745!important;
    text-shadow: none;
    &:hover,
    &:focus {
      color: rgba(40, 167, 69, 0.75)!important;
      background: transparent!important;
      border-color: rgba(40, 167, 69, 0.75)!important;
    }
    &:active,
    &.active {
      color: #28a745!important;
      background: transparent!important;
      border-color: #1e7e34!important;
    }
  }
 
  .ant-btn-warning {
    color: #fff!important;
    background-color: #eca52b!important;
    border-color: #eca52b!important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    &:hover,
    &:focus {
        color: #fff!important;
        background-color: rgba(236, 165, 43, 0.75)!important;
        border-color: rgba(236, 165, 43, 0.1)!important;
    }
    &:active,
    &.active {
        color: #fff!important;
        background-color: #97938b!important;
        border-color: #d18b13!important;
    }
  }
  .ant-btn-warning-disabled,
  .ant-btn-warning.disabled,
  .ant-btn-warning[disabled],
  .ant-btn-warning-disabled:hover,
  .ant-btn-warning.disabled:hover,
  .ant-btn-warning[disabled]:hover,
  .ant-btn-warning-disabled:focus,
  .ant-btn-warning.disabled:focus,
  .ant-btn-warning[disabled]:focus,
  .ant-btn-warning-disabled:active,
  .ant-btn-warning.disabled:active,
  .ant-btn-warning[disabled]:active,
  .ant-btn-warning-disabled.active,
  .ant-btn-warning.disabled.active,
  .ant-btn-warning[disabled].active {
    .btn-disable();
  }
  
  .ant-btn-background-ghost.ant-btn-warning {
    color: #eca52b!important;
    background: transparent!important;
    border-color: #eca52b!important;
    text-shadow: none;
    &:hover,
    &:focus {
        color: rgba(236, 165, 43, 0.75)!important;
        background: transparent!important;
        border-color: rgba(236, 165, 43, 0.75)!important;
    }
    &:active,
    &.active {
        color: #eca52b!important;
        background: transparent!important;
        border-color: #d18b13!important;
    }
  }
  
  .ant-btn-info {
    color: #fff!important;
    background-color: #17a2b8!important;
    border-color: #17a2b8!important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    &:hover,
    &:focus {
        color: #fff!important;
        background-color: rgba(23, 162, 184, 0.75)!important;
        border-color: rgba(23, 162, 184, 0.1)!important;
    }
    &:active,
    &.active {
        color: #fff!important;
        background-color: #117a8b!important;
        border-color: #117a8b!important;
    }
  }
  .ant-btn-info-disabled,
  .ant-btn-info.disabled,
  .ant-btn-info[disabled],
  .ant-btn-info-disabled:hover,
  .ant-btn-info.disabled:hover,
  .ant-btn-info[disabled]:hover,
  .ant-btn-info-disabled:focus,
  .ant-btn-info.disabled:focus,
  .ant-btn-info[disabled]:focus,
  .ant-btn-info-disabled:active,
  .ant-btn-info.disabled:active,
  .ant-btn-info[disabled]:active,
  .ant-btn-info-disabled.active,
  .ant-btn-info.disabled.active,
  .ant-btn-info[disabled].active {
    .btn-disable();
  }
  
  .ant-btn-background-ghost.ant-btn-info {
    color: #17a2b8!important;
    background: transparent!important;
    border-color: #17a2b8!important;
    text-shadow: none;
    &:hover,
    &:focus {
        color: rgba(23, 162, 184, 0.75)!important;
        background: transparent!important;
        border-color: rgba(23, 162, 184, 0.75)!important
    }
    &:active,
    &.active {
        color: #17a2b8!important;
        background: transparent!important;
        border-color: #117a8b!important;
    }
  }
  
  .disable-animation:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
  