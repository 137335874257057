.productInventorySec{
  .ant-row{
    margin-left: 0px!important;
    margin-right: 0px!important;
  }
}
.walletTable{
    @import '../../../../assets/css/common.less';
    .ant-table table{
      tr{        
        &:last-child td{
          .br-b-0()!important;
        }
        td{
            .orderLabel {
                font-weight: 500;
                color: #777!important;
            }
            .plus{
              color:green
            }
            .minus{
              color:red
            }
        }
      }
    }
  }

  .hiddenItem{
    margin-bottom: 0px!important;
    line-height: 0px!important;
    .ant-form-item-control-input{
      min-height: 0px!important;
    }
  }

.btn--small,
.btn--small:hover {
    background-color: #F7D95F;
    border-color: #F7D95F;
    padding: 1px 5px !important;
    font-size: 12px;
    border-radius: 2px;
    color: #fff;
}

.btn--view .anticon-eye,.btn--view .anticon-edit{
    position: relative;
    top: -4px;
    left: 0px;
}  

.productViewTable{
  table{
    thead{
      th{
        background: #E9ECEF;
      }
    }
    tbody{
      tr{
        td{
          border-color: #F0F0F0!important;
          padding: 0.8rem 0.5rem!important;
        }
        &:last-child{
          td{
            border-bottom: 0px;
          }
        }
      }
    }
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  /* border-color: #1890ff; */
  border-color: #d9d9d9 !important;
}

.ant-checkbox-wrapper {
  cursor: default !important;
}

.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-input {
  cursor: default !important;
}
